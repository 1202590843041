@import "../styles/responsive.scss";
@import "../styles/variables.scss";

.main-container {
  background-color: $bg-main;
  display: flex;
  height: 100vh;
  overflow: auto;
  @include respond-to("small") {
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }
  .main-left-container {
    background-color: bg-linear-blue;
  }
  .main-right-container {
    background-color: white;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: -webkit-fill-available;
  }
}
.mobile-header-container {
  width: -webkit-fill-available;
}
