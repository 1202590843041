@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";

.store-container {
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: column;
  .mainsearchfield.button-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .button {
      background-color: "#0063cc";
    }
    .search-field {
      display: flex;
      align-items: center;
      .main-search-icon {
        cursor: pointer;
      }
    }
  }
  .filters-row {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
  .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 0.2fr;
  }
  .clickable {
    cursor: pointer;
    color: #008952;
  }
  .si-table-container {
    .si-row {
      grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
    }
    @include respond-to("small") {
      .si-row {
        grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
      }
    }
  }
}

.css-llnh2x-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #008952 !important;
}
.select-dropdown {
  margin-bottom: 10px;
}
