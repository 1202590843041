@import "../../styles/responsive.scss";

.time-line-c {
  position: relative;
  .dot {
    position: absolute;
    width: 24px;
    height: 24px;
    background: #008952;
    border-radius: 50%;
  }
  .no-line {
    min-width: 500px;
    margin-left: 12px;
    min-height: 6em;
    @include respond-to("small") {
      min-width: auto;
    }
  }
  .line {
    @extend .no-line;
    border-left: 1px solid #008952;
  }

  .component {
    padding-left: 20px;
    min-width: 250px;
  }
}
