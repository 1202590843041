.blog-screen {
  margin-top: 1.5em;
  .tab {
    font-size: large;
    font-weight: bolder;
  }
}
.latest-article {
  max-width: 93%;
  margin: 0 auto;
  h1 {
    margin: 0px 0px 20px;
  }
  p {
    margin: 0px 0px 50px;
    font-size: x-large;
  }
  .recent-post-con {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: baseline;
    align-items: baseline;
    border-bottom: 1px solid #e5e9ec;
    padding-bottom: 2em;
    margin: 6em 0 1em 0;
    h3 {
      font-size: 30px;
    }
  }
  .post-card {
    max-width: 90%;
    border-radius: 10px;
    overflow: hidden;
    img {
      max-width: 100%;
      height: min-content;
    }
    h3 {
      padding: 0.5em 0 0.1em 0;
    }
  }
  .other-card {
    margin-bottom: 2em;
  }
}

.commonFooterContainer {
  .aboutUs {
    display: flex;
    justify-content: center;
    background-color: #008938;
    color: white;
    padding: 2em 3em 2em 5em;
    h1 {
      font-size: xxx-large;
      margin-bottom: 1em;
    }
    .about-us-content {
      display: grid;
      grid-template-columns: 1fr 0.7fr;
      column-gap: 10px;
      // align-items: center;
      font-weight: lighter;
      font-size: x-large;
      p {
        margin-top: 1em;
      }
      .learn-More-Button {
        background: #a1d242;
        margin-top: 1rem;
        a {
          color: white;
          text-decoration: none;
          font-weight: bold;
        }
      }

      img {
        width: 95%;
        max-height: 100%;
        border-radius: 5px;
        object-fit: contain;
      }
    }
  }
}
.main-edit-con {
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 2fr;
  .edit-con {
    width: 90%;
    margin: 0 auto;
    .edit-section {
      margin-top: 1rem;
      h2 {
        font-weight: bolder;
        color: black;
        border-bottom: 1px solid #e5e9ec;
        padding-bottom: 0.5em;
        margin-bottom: 20px;
      }
      .edit-grid {
        display: grid;
        grid-template-columns: 1fr 5fr;
        align-items: center;
        column-gap: 10px;
        margin-bottom: 2rem;
        border-bottom: 1px solid #e5e9ec;
        padding-bottom: 20px;
        margin-bottom: 20px;
        .editor-pick-image {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: contain;
        }
        .title {
          text-transform: uppercase;
          font-size: large;
          font-weight: bold;
          letter-spacing: inherit;
          color: black;
        }
        .editor-pick-image:hover {
          cursor: pointer;
        }
        .editor-text:hover {
          cursor: pointer;
        }
      }
      .main-faq-container {
        row-gap: 20px;
        display: grid;
        grid-template-rows: 1fr;
        .faq-container {
          display: grid;
          grid-template-columns: 1fr 4fr 1fr;
          align-items: center;
          row-gap: 20px;
          padding: 20px;

          .faq-img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
          .main-text {
            display: flex;
            justify-content: center;
            font-size: large;
          }
        }
        .faq-container:hover {
          background-color: black;
          cursor: pointer;
          transition: background-color 1000ms ease-in-out;
          color: white;
        }
      }
      
      
    }
  }
  .subscribe-customer-con {
    display: flex;
    justify-content: center;
    padding: 1rem;
    width: 75%;
    min-height: 90%;
    background: rgb(248, 249, 252);
    border-radius: 6px;

    p {
      font-size: x-large;
      margin-bottom: 2rem;
    }
    .subscribe-customer-con-image {
      display: flex;
      justify-content: center;
    }
    .subcribe-text-field {
      margin-right: 1em;
    }
  }
}

.news-con {
  max-width: 90%;
  margin: 0 auto;

  .news-section {
    h1 {
      margin-bottom: 1rem;
      color: black;
    }
    .news-item-container {
      margin-top: 2rem;
      h3 {
        color: black;
      }
    }
    .news-item-container:hover {
      cursor: pointer;
    }
  }
}
.header-con {
  background: #ffffff;
  box-sizing: border-box;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  top: 0px;
  z-index: 1;
  .header-content {
    width: 92%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo-con {
      display: flex;
      align-items: center;
      .logo {
        width: 180px;
      }
      span {
        font-size: x-large;
        margin-left: 0.2em;
        color: #14754e;
      }
    }
  }
}
