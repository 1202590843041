/** @format */

@import "../../styles/responsive.scss";
.tab-container {
  z-index: 1;
  position: fixed;
  width: 85%;
  background-color: white;
}
.dashboard-body {
  margin-top: 3em;
}
.heading {
  font-size: 18px;
  // font-weight: bold;
  padding-bottom: 5px;
  padding-top: 5px;
}

.m0 {
  margin: 0px !important;
}

.LeftMargin {
  margin-left: 1rem;
}

.leftAuto {
  margin-left: auto;
}
.date-container {
  display: flex;
  z-index: 1;
  .dateFilter {
    float: right;
    width: 120%;
    margin-top: 5px;
  }
}

.custom-tabs {
  .MuiButtonBase-root {
    padding: 15px !important;
  }
}

.custom-toggle {
  .MuiButtonBase-root {
    padding: 10px !important;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
  }
  .departmentAndCategoryContainer {
    .BellcorpStudioLogo {
      right: 2%;
    }
  }
}

.BottomMargin {
  margin-bottom: 1rem;
}

.apply {
  margin-left: 10px !important;
  margin-top: -3px !important;
  padding: 4px !important;
}
.react-daterange-picker__wrapper {
  padding: 5px;
  border-radius: 10px;
}
.clearicon {
  cursor: pointer;
  position: relative;
  top: 7px;
  margin-left: 5px;
}

.card-base {
  padding: 0px !important;
}

.f10 {
  font-size: 10px;
}

.f15 {
  font-size: 15px;
}

.support-person-value-label {
  padding-left: 0px;
  font-size: 20px;
}

.support-person-value {
  font-size: 12px;
  padding: 5px;
}

.support-person-value-container {
  font-weight: bold;
  margin-left: 0px;
  width: 100%;
}

.support-person-container {
  background-color: white;
  box-shadow: 0 2px 14px 0 #20282d2e;
  height: 100%;
  border-radius: 20px;
}

.hw100 {
  height: 100px;
  width: 100px;
}

.name-avatar {
  height: 40px;
  width: 40px;
  margin-right: 5px;
}

.card-conent {
  box-shadow: 5px 5px 10px 5px #eceff1;
  background-color: white;
  margin: 10px !important;
  border-radius: 5px;
  @include respond-to("small") {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.pl10 {
  padding-left: 10px;
  // font-weight: bolder;
}

.f22 {
  font-size: 22px;
}

.pt15 {
  padding-top: 15px;
}

.pt50 {
  padding-top: 50px;
}

.icon {
  color: white;
  margin: 10px;
  border-radius: 10px;
  padding: 7px;
}

.plainIcon {
  position: relative;
  top: 3px;
  margin: 5px;
}

.icon-span {
  position: relative;
  top: -7px;
}
.bolder {
  font-weight: bolder;
}

.chart-icon-blue {
  color: #7986cb;
  width: 120px !important;
  height: 130px !important;
  position: relative;
  top: -15px;
  left: -10px;
}

.height100 {
  height: 100px;
}

.chart-icon-orange {
  color: #f5915a;
  width: 120px !important;
  height: 130px !important;
  position: relative;
  top: -15px;
  left: -10px;
}

.float-right {
  float: right;
  position: relative;
  top: 5px;
  margin: 5px;
}

.p10 {
  padding: 10px;
}

.p30 {
  padding: 30px 15px 15px 15px;
}

.boder-grid {
  border: 1px solid gray;
}

.gray {
  color: gray;
}

.left-radius {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.right-radius {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.toggle-button {
  position: relative;
  left: 85%;
  top: 5px;
  @include respond-to("small") {
    position: inherit;
  }
}

.h0 {
  height: 0px;
}

.inner-tab {
  margin: 20px 0px 0px 0px;
}

.active-inner-tab {
  background-color: #feba12;
  color: white;
}

.in-active-inner-tab {
  background-color: white;
  color: black;
}

.over-view-line-chart {
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.2);
  padding: 5px;
  margin: 10px;
  // position: relative;
  // top: 48px;
}

.bar-chart-container {
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  .title {
    font-size: 18px;
    //  font-weight: bold;
    padding-bottom: 40px;
  }
}

.pic-chart-container {
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  .title {
    font-size: 18px;
    // font-weight: bold;
    padding-bottom: 10px;
  }
}

.subnavbar {
  padding: 5px 15px 5px 15px;
  margin: 0px 5px 0px 5px;
  border-radius: 20px;
  cursor: pointer;
}

.other-info-container {
  display: grid;
  grid-template-columns: 48.5% 48.5%;
  grid-gap: 20px;
  @include respond-to("small") {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }
}

.flex {
  display: flex;
}

.pt5 {
  padding-top: 5px;
}

.pt0 {
  padding-top: 0px;
}

.storeDashboardContainer {
  .BellcorpStudioLogo {
    right: 4%;
  }
}

.overview-Container {
  .BellcorpStudioLogo {
    right: 2%;
  }

  @media (max-width: 750px) {
    .BellcorpStudioLogo {
      bottom: -2%;
      right: 3%;
    }
  }

  @media (max-width: 550px) {
    .BellcorpStudioLogo {
      right: 5%;
    }
  }
}

@media (max-width: 1024px) {
  .supportPersonContainer.BellcorpStudioLogo {
    right: 2%;
  }
}

@media (max-width: 700px) {
  .supportPersonContainer.BellcorpStudioLogo {
    right: 3%;
  }
}

@media (max-width: 550px) {
  .supportPersonContainer.BellcorpStudioLogo {
    right: 5%;
  }
}

@media (max-width: 800px) {
  .storeDashboardContainer {
    .BellcorpStudioLogo {
      right: 6%;
    }
  }
}
