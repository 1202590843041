.reports-table {
  .si-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 0.2fr;
  }
  .clickable {
    cursor: pointer;
    color: #306fff;
  }

  .filter-section {
    padding: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    grid-gap: 20px;
    .left-s {
      display: flex;
      align-items: flex-end;
      grid-gap: 20px;
    }

    .hrm-range {
      display: flex;
      align-items: center;
      grid-gap: 20px;
    }
    .si-select__control {
      min-width: 200px;
    }
  }
  .clickable.userClickable {
    cursor: pointer;
    color: #306fff;
    text-decoration: underline;
  }

  .user-info-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
}
// date picker styling
.rs-picker-default .rs-picker-toggle.rs-btn {
  width: 282px;
  padding: 10px;
  height: 2.8em;
}
.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 67px;
  display: inline-block;
  outline: none;
  cursor: pointer;
  color: #575757;
  border: 1px solid #e5e5ea;
  padding-left: 44px;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: #008952 !important;
}
.rs-picker-daterange-panel .rs-btn-link {
  color: #008952 !important;
}
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  padding-left: 0;
  padding-right: 0;
  border-radius: 21px;
  display: inline-block;
}
.rs-btn-primary {
  background-color: #008952 !important;
}
.rs-picker-daterange-header {
  color: #008952;
}
.rs-picker-toggle-value {
  color: #008952 !important;
}
.clickable {
  cursor: pointer;
  color: #008a52;
}

.custom-modal-container .modal-content.tableModal {
  width: 80%;
  max-width: 80%;
}
