@import "../../styles/responsive.scss";
@import "../../styles/variables.scss";
.si-table-container {
  overflow: auto;
  .si-header {
    .si-row {
      display: grid;
      background: #008952;
      border: 1px solid #e1e7ff;
      box-sizing: border-box;
      height: 63px;
      color: white;
      align-items: center;
      padding: 0px 20px;
      @include respond-to("small") {
        padding: 0px 10px;
      }
    }
  }
  .si-body {
    max-height: 90vh;
    overflow: auto;
    .si-row {
      display: grid;
      border: 1px solid #e1e7ff;
      box-sizing: border-box;
      height: 43px;
      align-items: center;
      padding: 0px 20px;
      @include respond-to("small") {
        padding: 0px 10px;
      }
      .si-cell-mobile {
        font-family: ProximaNovaAltLight-Regular;
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 1.5;
      }
    }

    .clickable {
      cursor: pointer;
      color: #008952;
    }
    .no-records {
      display: flex;
      justify-content: center;
      padding: 20px;
    }
  }
  .si-pagination {
    display: flex;
    justify-content: center;
    padding: 20px 0px;
  }
}
