.side-menu-container {
  width: 200px;
  height: 100%;
  min-height: 100vh;
  background: #008952;
  .logo {
    max-width: -webkit-fill-available;
  }
  .menu-items {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;

    .menu-item {
      display: flex;
      grid-gap: 20px;
      cursor: pointer;
      align-items: center;
      margin-top: 1em;
      padding: 5px 0px 5px 40px;
      .menu-name {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: white;
      }
    }
    .menu-item-active {
      background: #005442;
    }
  }
  .logout-section {
    bottom: 10px;
    position: fixed;
  }
  .user-menu-container {
    display: flex;
    align-items: center;
    padding: 1em;
  }
}

.logogimage {
  max-width: 100%;
}

.align-center {
  display: flex;
  align-items: center;
}

.sidebarmenulabel {
  padding-left: 0.5rem;
}
